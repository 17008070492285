<template>
    <span>
        <span v-show="field !== sortedField"> <i class="fas fa-sort" /> </span>
        <span v-show="field === sortedField && isSortDescending"> <i class="fas fa-sort-down" /> </span>
        <span v-show="field === sortedField && !isSortDescending"> <i class="fas fa-sort-up" /> </span>
    </span>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      required: true
    },
    sortedField: {
      type: String,
      required: false
    },
    isSortDescending: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style>
.fa-sort {
  opacity: 0.5;
}
</style>
