<template>
  <div class="custom-sites--root">
    <template>
      <div class="custom-sites--header">
        <b-input
          size="is-medium"
          placeholder="Search client"
          v-model="gridSearchCriteria"
          icon-pack="fas"
          icon="search"
          icon-clickable
          @icon-click="searchCustomSites"
          @input="searchCustomSites"
        ></b-input>
      </div>

      <b-field class="custom-sites--results" v-if="isSearchNetworks">
        <span>{{ customSitesList.length }} {{ customSitesList.length === 1 ? 'result' : 'results' }} found</span>
      </b-field>

      <b-table
        ref="gridTable"
        class="custom-sites--table"
        :class="{ 'custom-is-empty': !customSitesList || customSitesList.length === 0 }"
        :data="customSitesList.slice(firstIndex, lastIndex)"
        :height="600"
        default-sort="clientName"
        sort-icon="plus"
        sticky-header
        bordered
        @sort="onSort"
      >
        <b-table-column
          sortable
          field="clientName"
          label="Client Name"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <SortIcons
              :field="column.field"
              :sortedField="sortedField"
              :isSortDescending="isSortDescending"
            />
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.clientName) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column
          sortable
          field="oeUrlName"
          label="Pre-Enrollment URL name"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <SortIcons
              :field="column.field"
              :sortedField="sortedField"
              :isSortDescending="isSortDescending"
            />
          </template>
          <template v-slot="props">
            <span>
              {{ getFormattedString(props.row.oeUrlName) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column sortable field="status" label="Status">
          <template v-slot:header="{ column }">
            {{ column.label }}
            <SortIcons
              :field="column.field"
              :sortedField="sortedField"
              :isSortDescending="isSortDescending"
            />
          </template>
          <template v-slot="props">
            <span :class="`custom-status-text ${props.row.status}`">
              {{ getFormattedString(props.row.status) }}
            </span>
          </template>
        </b-table-column>

        <b-table-column label="Edit" v-slot="props" width="150">
          <b-field class="column-icons">
            <router-link
              class="status-hover-text"
              :data-hover="!!siteStatus(props.row.status) ? 'Edit' : 'Start'"
              :to="{ name: 'SiteCustomization', params: { clientName:  props.row.clientName } }"
            >
              <img v-if="!!siteStatus(props.row.status)" src="../assets/edit_button.svg" alt="Edit plan" />
              <img v-else src="../assets/select_button.svg" alt="Start plan"/>
            </router-link>
          </b-field>
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import store from '@/store/store'
import _ from 'lodash'
import SortIcons from './../components/sort-icons.vue'

export default {
  name: 'custom-sites-management',
  components: {
    SortIcons
  },

  mounted () {
    const tableScrollBody = this.$el.getElementsByClassName('table-wrapper')[0]
    tableScrollBody.addEventListener('scroll', this.onBodyScroll)
  },

  beforeDestroy () {
    const tableScrollBody = this.$el.getElementsByClassName('table-wrapper')[0]
    tableScrollBody.removeEventListener('scroll', this.onBodyScroll)
  },

  data () {
    this.getCustomSitesList()

    return {
      customSitesList: [],
      firstIndex: 0,
      lastIndex: 15,
      oldScrollValue: 0,
      gridSearchCriteria: '',
      rowData: null,
      sortedField: '',
      isSortDescending: false,
      isSearchNetworks: false
    }
  },

  methods: {
    onBodyScroll: _.debounce(function (event) {
      if (
        event.target.scrollTop + event.target.clientHeight + 500 >=
          event.target.scrollHeight &&
        this.customSitesList.length >= this.lastIndex
      ) {
        this.lastIndex += 5
      }
    }, 100),

    searchCustomSites: _.debounce(function () {
      const lowerSearchCriteria = this.gridSearchCriteria.toLowerCase()
      const storeCustomSitesList = this.getCustomSitesListFromStore()

      if (lowerSearchCriteria) {
        this.customSitesList = storeCustomSitesList.filter(
          (client) =>
            client.clientName.toLowerCase().includes(lowerSearchCriteria) ||
            client.oeUrlName.toLowerCase().includes(lowerSearchCriteria)
        )
      } else {
        this.customSitesList = storeCustomSitesList ?? []
      }

      this.isSearchNetworks = !!lowerSearchCriteria
    }, 400),

    getFormattedString: (value) => value ?? '',

    getCustomSitesList () {
      this.$store
        .dispatch('customSites/getCustomSites')
        .then(() => {
          this.customSitesList = this.getCustomSitesListFromStore() ?? []
        })
        .catch(console.error)
    },

    getCustomSitesListFromStore () {
      return store.getters['customSites/getCustomSitesGrid']
    },

    onSort (event) {
      if (this.sortedField === event) {
        if (this.isSortDescending) {
          this.sortedField = ''
          return
        }

        this.isSortDescending = !this.isSortDescending
      } else this.isSortDescending = false
      this.sortedField = event
    },

    siteStatus (status) {
      return status.toLowerCase() !== 'not started'
    }
  }
}
</script>

<style lang="scss">

.custom-sites--header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.9rem;
  padding-top: 3.5rem;

  > .control {
    width: 480px;
  }

  > a {
    display: flex;
    align-items: center;
    color: #ec008c;
    font-weight: 600;

    img {
      margin-left: 0.375rem;
    }
  }
}

.custom-sites--results {
  display: flex;
  font-size: 12px;
  margin-top: -1.275rem;
  margin-bottom: 4px !important;
  color: #000;
  font-weight: 500;
}

.custom-sites--table {
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 3rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;

  &.custom-is-empty {
    > div.table-wrapper > table {
      min-height: 37.5rem;
    }
  }

  > div.table-wrapper > table {

    th {
      border: none;
      background: #EBEBEB !important;
      font-weight: 600;
      padding: 0.75rem;

      span {
        color: #444;
      }

      svg {
        color: #7A7E81;
        margin-left: 0.3rem;
      }
    }

    td {
      padding: calc((3.75rem - 2rem) / 2) 0.75rem;
      text-align: left;
      vertical-align: middle;

      .column-icons {
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      span {
        height: min-content;
        color: #444;
      }
    }
  }

  .custom-status-text {
    &.Published {
      color: #3BB02E;
    }

    &.Not\ Started {
      color: #444;
    }

    &.Unpublished {
      color: #7A7E81;
    }
  }
}

.status-hover-text {
  position: relative;
}

.status-hover-text::before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 50px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 5px;
  padding: 1px 0;
  transition: opacity 1s ease-in-out;

  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.status-hover-text:hover::before {
  opacity: 1;
  visibility: visible;
}

</style>
